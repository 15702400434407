import Quarantine from 'images/blogs/blog-19.png'
import Earn from 'images/blogs/blog-20.png'
import Blog from 'images/blogs/blog-22.png'
import 'components/css/blog-section.css'
import 'animate.css/animate.min.css'

const digipayStoriesData = [
  {
    cardImageSrc: Blog,
    cardTitleLineOne: 'PERA PADALA',
    cardTitleLineTwo: '#DIGIPADALANAYAN',
    cardDescription:
      'Alamin kung paano at saan maaaring magpadala o tumanggap ng pera gamit ang Digipadala!',
    readMoreButtonHref: '/stories/PeraPadala',
  },

  {
    cardImageSrc: Earn,
    cardTitleLineOne: '3 WAYS TO EARN‌ USING',
    cardTitleLineTwo: 'DIGIPAY',
    cardDescription:
      'Alamin kung anu-ano ang extra income na makukuha mo sa Digipay!',
    readMoreButtonHref: '/stories/3WaysToEarnUsingDigipay',
  },

  {
    cardImageSrc: Quarantine,
    cardTitleLineOne: '3‌ ‌BAGAY‌ ‌NA‌ ‌PWEDENG‌ ‌GAWIN‌ ‌SA‌ ‌BAHAY',
    cardTitleLineTwo: 'DURING‌ ‌QUARANTINE‌',
    cardDescription:
      'Paano‌ ‌maging‌ ‌productive‌ ‌kahit‌ ‌nasa‌ ‌bahay‌ ‌ka‌ ‌lang?‌',
    readMoreButtonHref: '/stories/3PwedengGawinSaBahay',
  },
]

export default digipayStoriesData
