import React from 'react'
import 'components/css/about-digipay.css'
import 'animate.css/animate.min.css'

const AboutDigipaySection = () => (
  <div className="about-digipay">
    <p align="center">
      <span className="about__company-name">Digipay</span> is a digital payments
      and financial services platform with <br />
      a mobile wallet, an industry leading biller ecosystem,
      <br />
      and an interoperable backend.
    </p>
    <hr />
  </div>
)
export default AboutDigipaySection
