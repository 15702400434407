import React, { Children, cloneElement, Fragment } from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import BillsPayment from 'images/icons/bills-payment.svg'
import ELoad from 'images/icons/e-load.svg'
import EPins from 'images/icons/e-pins.svg'
import MicroInsurance from 'images/icons/microinsurance.svg'
import MobileMoney from 'images/icons/mobile-money.svg'
import 'components/css/services.css'

class Service extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem: null,
    }
  }

  static Item = ServiceItem

  setActiveItem = itemIndex => () => {
    this.setState({ activeItem: itemIndex })
  }

  render() {
    const children = Children.map(this.props.children, (child, index) => {
      if (child.type !== ServiceItem) {
        return child
      }

      const props = {
        setActiveItem: this.setActiveItem(index),
        isActiveItem: this.state.activeItem === index,
      }

      return cloneElement(child, props)
    })

    const content =
      this.state.activeItem === null
        ? null
        : this.props.children[this.state.activeItem].props.children

    // console.log(content)

    return (
      <Fragment>
        <Row>{children}</Row>
        {content ? <Row>{content}</Row> : null}
      </Fragment>
    )
  }
}

function ServiceItem(props) {
  const { icon, title, children, setActiveItem, isActiveItem } = props
  return (
    <Col className="service" onMouseOver={setActiveItem}>
      <div className="service__item text-center">
        <Image className="service__product-image" src={icon} alt={title} />
        <h6>{title}</h6>
      </div>
    </Col>
  )
}

const ServiceSection = () => (
  <div>
    <h3 className="service__title">What are you looking for?</h3>
    <div className="service__list">
      <Service>
        <Service.Item icon={BillsPayment} title="Bills Payment">
          <Row>
            <Col className="service__category-items">
              <ul>
                <li>
                  <a href="#Airlines">Airlines</a>
                </li>
                <li>
                  <a href="#Credit Cards">Credit Cards</a>
                </li>
                <li>
                  <a href="#Cable Channel Provider">Cable Channel Provider</a>
                </li>
                <li>
                  <a href="#Consumer Finances">Consumer Finances</a>
                </li>
              </ul>
            </Col>
            <Col className="service__category-items">
              <ul>
                <li>
                  <a href="#Government Services">Government Services</a>
                </li>
                <li>
                  <a href="#Health Care">Health Care</a>
                </li>
                <li>
                  <a href="#Insurance and Fin Services">
                    Insurance and Fin Services
                  </a>
                </li>
                <li>
                  <a href="#Loans">Loans</a>
                </li>
              </ul>
            </Col>
            <Col className="service__category-items">
              <ul>
                <li>
                  <a href="#Payment Gateway">Payment Gateway</a>
                </li>
                <li>
                  <a href="#Power and Water">Power and Water</a>
                </li>
                <li>
                  <a href="#Real Estate">Real Estate</a>
                </li>
                <li>
                  <a href="#Schools">Schools</a>
                </li>
              </ul>
            </Col>
            <Col className="service__category-items">
              <ul>
                <li>
                  <a href="#Telecommunication">Telecommunication</a>
                </li>
                <li>
                  <a href="#Transportation">Transportation</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Service.Item>

        <Service.Item icon={ELoad} title="E-Load">
          <Col md={8}>
            <Row>
              <Col md={4} />
              <Col md={2} className="service__category-items">
                <ul>
                  <li>
                    <a href="#ABS-CBN">ABS-CBN</a>
                  </li>
                  <li>
                    <a href="#Cignal">Cignal</a>
                  </li>
                  <li>
                    <a href="#Globe">Globe</a>
                  </li>
                  <li>
                    <a href="#PLDT">PLDT</a>
                  </li>
                </ul>
              </Col>
              <Col md={2} className="service__category-items">
                <ul>
                  <li>
                    <a href="#Smart">Smart</a>
                  </li>
                  <li>
                    <a href="#Sun Cellular">Sun Cellular</a>
                  </li>
                  <li>
                    <a href="#Touch Mobile">Touch Mobile</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Service.Item>

        <Service.Item icon={EPins} title="E-Pins">
          <Col md={2} />
          <Col md={12}>
            <Row>
              <Col md={5} />
              <Col md={2} className="service__category-items">
                <ul>
                  <li>
                    <a href="#Communication">Communication</a>
                  </li>
                  <li>
                    <a href="#Entertainment">Entertainment</a>
                  </li>
                  <li>
                    <a href="#Gaming">Gaming</a>
                  </li>
                  <li>
                    <a href="#Insurance">Insurance</a>
                  </li>
                </ul>
              </Col>
              <Col md={2} className="service__category-items">
                <ul>
                  <li>
                    <a href="#Power">Power</a>
                  </li>
                  <li>
                    <a href="#Others">Others</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Service.Item>

        <Service.Item icon={MobileMoney} title="Mobile Money">
          <Col md={3} />
          <Col md={8}>
            <Row>
              <Col md={7} />
              <Col className="service__category-items">
                <ul>
                  <li>
                    <a href="#GCash">GCash</a>
                  </li>
                  <li>
                    <a href="#Paymaya">Paymaya</a>
                  </li>
                  <li>
                    <a href="#Smart">Smart</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Service.Item>

        <Service.Item icon={MicroInsurance} title="Microinsurance">
          <Col md={6} />
          <Col md={12}>
            <Row>
              <Col md={10} />
              <Col className="service__category-items">
                <ul>
                  <li>
                    <a href="#Healthguard">Healthguard</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Service.Item>
      </Service>
    </div>
  </div>
)
export default ServiceSection
