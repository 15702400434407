import React from 'react'
import Download from 'images/icons/download.gif'
import { Col, Row, Image } from 'react-bootstrap'
import SignUp from 'images/icons/signup.gif'
import Pay from 'images/icons/pay.gif'
import Laptop from 'images/bg/laptop.png'
import 'components/css/get-digipay.css'
import 'animate.css/animate.min.css'
import ScrollAnimation from 'react-animate-on-scroll'

const getDigipayData = [
  {
    stepNumber: '01',
    stepImageSource: Download,
    stepHeader: 'DOWNLOAD',
    stepDescription: 'Download the App from the Playstore',
  },
  {
    stepNumber: '02',
    stepImageSource: SignUp,
    stepHeader: 'SIGN UP',
    stepDescription:
      'Fill up the application form and submit the requirements needed.',
  },
  {
    stepNumber: '03',
    stepImageSource: Pay,
    stepHeader: 'PAY',
    stepDescription:
      'Digipay Sales will contact agent to verify application. Deposit the payment.',
  },
]

export default getDigipayData
