import React from 'react'
import 'components/css/promo-video.css'
import YouTube from 'react-youtube'

class PromotionalVideo extends React.Component {
  scrollListener = evt => {
    if (window.scrollY >= this.container.offsetTop) {
      this.yt.internalPlayer.playVideo()
    } else {
      this.yt.internalPlayer.pauseVideo()
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollListener)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener)
  }

  render() {
    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 0,
        loop: 1,
        modestbranding: 1,
        controls: 0,
        showinfo: 0,
        rel: 0,
      },
    }
    return (
      <div className="promotional-video" ref={el => (this.container = el)}>
        <YouTube ref={yt => (this.yt = yt)} videoId="zel7SQgwKoo" opts={opts} />
      </div>
    )
  }
}
export default PromotionalVideo
