import React from 'react'
import Download from 'images/icons/download.gif'
import { Col, Row, Image } from 'react-bootstrap'
import SignUp from 'images/icons/signup.gif'
import Pay from 'images/icons/pay.gif'
import Laptop from 'images/bg/laptop.png'
import 'components/css/get-digipay.css'
import 'animate.css/animate.min.css'
import ScrollAnimation from 'react-animate-on-scroll'
import getDigipayData from '../../data/static/GetDigipayData'

const GetDigipaySection = () => (
  <div className="get-digipay">
    <h1 align="center" className="get-digipay__header">
      HOW TO BE A DIGIPAY AGENT?
    </h1>
    <Row>
      <Col className="get-digipay__instructions" xs={12} md={6}>
        {getDigipayData.map((value, index) => {
          return (
            <ScrollAnimation key={index} animateIn="fadeInLeft">
              <Row className="get-digipay__step">
                <h2 className="get-digipay__step-number">{value.stepNumber}</h2>
                <Col>
                  <Image
                    className="get-digipay__step-image"
                    src={value.stepImageSource}
                  />
                  <Row className="get-digipay__step-content">
                    <h4 className="get-digipay__step-header">
                      {value.stepHeader}
                    </h4>
                  </Row>
                  <Row className="get-digipay__step-content">
                    <p className=".get-digipay__step-description">
                      {value.stepDescription}
                    </p>
                  </Row>
                </Col>
              </Row>
            </ScrollAnimation>
          )
        })}
      </Col>

      <Col xs={6} className="d-none d-md-block">
        <Image id="laptop-image" src={Laptop} alt="laptop-image" />
      </Col>
    </Row>
  </div>
)
export default GetDigipaySection
