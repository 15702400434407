import React from 'react'
import { Row, Col } from 'react-bootstrap'
import 'components/css/mission-vision.css'

const MissionVision = () => (
  <Row className="about-mobile">
    <Col lg={6}>
      <h1 align="left">ABOUT</h1>
      <p>
        <span className="about-mobile__styledText">DIGIPAY</span> is a digital
        payments and financial services platform with a mobile wallet, an
        industry leading biller ecosystem and an interoperable backend. The
        delivery platform empowers businesses and consumers alike to perform
        essential financial transactions on their smart phones or at any of the
        multiple agent locations.
      </p>
      <br />
      <p>
        DIGIPAY was launched in November 2017. It is owned by{' '}
        <span className="about-mobile__styledText">
          FSG Technology Ventures, Inc.
        </span>
        , <br />a subsidiary of FSG Capital, Inc.
      </p>
    </Col>
    <Col className="about-mobile__mission-vision" lg={6}>
      <Row className="about-mobile__mission">
        <div className="about-mobile__mission-content">
          <h4>
            Mission
            <hr />
          </h4>

          <p>
            To empower communities with convenient, secure and trustworthy
            financial and payment solutions.
          </p>
        </div>
      </Row>
      <Row className="about-mobile__vision">
        <div className="about-mobile__vision-content">
          <h4>
            Vision
            <hr />
          </h4>

          <p>Social and financial upliftment for those who need it most.</p>
        </div>
      </Row>
    </Col>
  </Row>
)
export default MissionVision
