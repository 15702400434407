import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import 'components/css/sign-up.css'
import 'animate.css/animate.min.css'
import ScrollAnimation from 'react-animate-on-scroll'
import HostName from '../../data/static/HostNames'
const SignUpSection = () => (
  <div className="sign-up">
    <Row className="sign-up--layout-controller">
      <Col className="sign-up__content" align="center" lg={6}>
        <ScrollAnimation animateIn="bounceInLeft">
          <h2>Want to be a Digipay Agent?</h2>
        </ScrollAnimation>
      </Col>
      <Col className="sign-up__content" align="center" lg={6}>
        <ScrollAnimation animateIn="bounceInRight">
          <Button
            variant="light"
            href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-up`}
            className="sign-up__btn-sign-up"
          >
            <h2>SIGN UP</h2>
          </Button>
        </ScrollAnimation>
      </Col>
    </Row>
  </div>
)
export default SignUpSection
