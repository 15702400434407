import React from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import classnames from 'classnames'
import 'components/css/testi-mobile.css'
import Agent1 from 'images/testi/Joe Clarino.png'
import Agent2 from 'images/testi/Precy Cia.png'
import Agent3 from 'images/testi/Chona Selosa.png'
import Agent4 from 'images/testi/Eva Cadelino.png'

var agents = [
  {
    name: 'Joe Clarino',
    text:
      'Very intuitive to use, mabilis, at reliable ang Digipay app. Kahit ang mga frontliners ko hindi nahirapan gamitin ito. Madali din kausap ang customer support. Nakita ko talaga ang improvement ng customer delivery and satisfaction gamit ang Digipay.',
    image: Agent1,
  },

  {
    name: 'Precy Cia',
    text:
      'Mabilis ang transactions gamit ang Digipay app. Makikita mo rin ang day-to-day transactions at income mo sa app. Bukod sa dagdag income, mas malapit na rin ang one-stop-shop kung saan pwedeng magbayad ang mga kapitbahay ko kaya mas makakatipid sila sa pamasahe at makakaiwas sa pila.',
    image: Agent2,
  },

  {
    name: 'Chona Selosa',
    text:
      'Malaking tulong sa time management ang Digipay dahil ako mismo ay hindi na kailangang umalis pa ng tindahan para magbayad ng bills. Kumikita ako and at the same time, nakakatulong din ako sa aking mga ka-barangay.',
    image: Agent3,
  },

  {
    name: 'Eva Cadelino',
    text:
      'Kung dati nasa bahay lang ako at nag-aalaga ng anak, ngayon may business na ako. Every transaction, may rebates kaya naman sobra akong nagpapasalamat sa DIGIPAY at sa mga taong naging instrumento ng system na ito. Hayahay ang buhay basta may Digipay!',
    image: Agent4,
  },
]

class MobileTesti extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentIndex: 0 }
  }

  componentDidMount() {
    var self = this
    this.intervalID = setInterval(function() {
      self.setState(state => {
        return { currentIndex: (state.currentIndex + 1) % agents.length }
      })
    }, 15000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
  }

  render() {
    const data = agents.map((agent, index) => (
      <div
        key={index}
        className={classnames(
          'testimonial-mobile__elements',
          index === this.state.currentIndex && 'show'
        )}
      >
        <div className="testimonial-mobile__content">
          <p align="center">{agent.text}</p>
        </div>
        <Row className="testimonial-mobile__agent" align="right">
          <Col align="center">
            <Image
              className="testimonial-mobile__agent-image"
              src={agent.image}
              alt="agent-1"
            />
          </Col>
          <Col className="testimonial-mobile__agent-fullname" align="right">
            <h3 align="center">{agent.name}</h3>
            <hr />
            <p align="center">Digipay Agent</p>
          </Col>
        </Row>
      </div>
    ))

    return <div className="testimonial-mobile">{data}</div>
  }
}
export default MobileTesti
