import React from 'react'
import { Col, Row } from 'react-bootstrap'
import 'components/css/about-section.css'

const AboutSection = () => (
  <div className="about">
    <div className="about__bg-image">
      <Row>
        <Col className="about__about-content" xs={12} md={5}>
          <h1 align="left">ABOUT</h1>
          <p align="left">
            <span className="about__about-content__styled-text">DIGIPAY</span>{' '}
            is a digital payments and financial services platform with a mobile
            wallet, an industry leading biller ecosystem and an interoperable
            backend. The delivery platform empowers businesses and consumers
            alike to perform essential financial transactions on their smart
            phones or at any of the multiple agent locations.
          </p>
          <br />
          <br />
          <p>
            DIGIPAY was launched in November 2017. It is owned by <br />
            <span className="about__about-content__styled-text">
              FSG Technology Ventures, Inc.
            </span>
            , <br />a subsidiary of FSG Capital, Inc.
          </p>
        </Col>

        <Col className="about__mission-vision" xs={12} md={6}>
          <Row>
            <div className="about__mission-content">
              <h3>
                Mission
                <hr />
              </h3>
              <p>
                To empower communities with convenient, secure and trustworthy
                financial and payment solutions.
              </p>
            </div>
          </Row>
          <Row>
            <div className="about__vision-content">
              <h3>
                Vision
                <hr />
              </h3>
              <p>Social and financial upliftment for those who need it most.</p>
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  </div>
)
export default AboutSection
