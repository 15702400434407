import React from 'react'
import { Link } from 'gatsby'
import { Card, Button, Col, Row } from 'react-bootstrap'
import Quarantine from 'images/blogs/blog-19.png'
import Earn from 'images/blogs/blog-20.png'
import Capital from 'images/blogs/blog-21.png'
import 'components/css/blog-section.css'
import 'animate.css/animate.min.css'
import ScrollAnimation from 'react-animate-on-scroll'
import digipayStoriesData from '../../data/static/DigipayStoriesData'
const BlogSection = () => (
  <div className="digipay-stories">
    <h1 align="center" className="font-weight-bold padding-bottom-md">
      DIGIPAY STORIES <hr />
    </h1>
    <Row className="digipay-stories__list">
      {digipayStoriesData.map((value, index) => {
        return (
          <Col
            key={index}
            className="digipay-stories__story"
            align="center"
            md={4}
          >
            <Card className="digipay-stories__story-content">
              <Card.Img variant="top" src={value.cardImageSrc} alt="guide-bg" />
              <Card.Body>
                <Card.Title className="digipay-stories__story-title">
                  {value.cardTitleLineOne}
                  <br />
                  {value.cardTitleLineTwo}
                </Card.Title>
                <Card.Text className="digipay-stories__story-description">
                  {value.cardDescription}
                </Card.Text>
                <Button
                  variant="danger"
                  className="digipay-stories__btn-read-more"
                  to={value.readMoreButtonHref}
                  as={Link}
                >
                  Read More
                </Button>
              </Card.Body>
            </Card>
          </Col>
        )
      })}

      <a
        align="center"
        className="blog__btn-read-more-stories"
        href="/blogs"
      >{`Read More Stories >>`}</a>
    </Row>
  </div>
)

export default BlogSection
